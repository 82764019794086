import React,{useEffect} from 'react'
import Layout from '@solid-ui-layout/Layout'
import theme from '@flexiblocks/templates/saas-v2/_theme'

const SuccessPage = props => {
  useEffect(()=>{
    window.location.href = `https://rhodium11.freshdesk.com/support/tickets/new`
  },[]);

  return ( <Layout theme={theme} {...props}/> )
}

export default SuccessPage